import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image, Space, Grid } from "antd";
import { getFirebaseImageURL } from "../../utils/firebase-storage";
import { Capture } from "../../features/captures/capturesSlice";

const CaptureViewer = ({
  lastCapture,
}: {
  lastCapture: Capture | undefined;
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [leftImage, setLeftImage] = useState<string | null>(null);
  const [rightImage, setRightImage] = useState<string | null>(null);
  const [roiImage, setRoiImage] = useState<string | null>(null);

  useEffect(() => {
    const fetchImages = async () => {
      if (lastCapture) {
        if (lastCapture.left_vizualization) {
          const url = await getFirebaseImageURL(lastCapture.left_vizualization);
          setLeftImage(url);
        }
        if (lastCapture.right_visualization) {
          const url = await getFirebaseImageURL(
            lastCapture.right_visualization
          );
          setRightImage(url);
        }
        if (lastCapture.roi_visualization) {
          const url = await getFirebaseImageURL(lastCapture.roi_visualization);
          setRoiImage(url);
        }
      }
    };
    fetchImages();
  }, [lastCapture]);

  const styles = {
    maxWidth: {
      maxWidth: "100%",
    },
  };

  const renderLayout = () => {
    if (!lastCapture) {
      return <div>Waiting for your next shot...</div>;
    }
    if (screens.xs) {
      // Mobile layout
      return (
        <Row justify="center" style={{ ...styles.maxWidth, padding: "5px" }}>
          <Col xs={24}>
            <Space
              direction="vertical"
              size={8}
              style={{ ...styles.maxWidth, minWidth: "100%" }}
            >
              <Card title="Left" style={{ ...styles.maxWidth, margin: "5px" }}>
                {leftImage && (
                  <Image src={leftImage} width="100%" preview={false} />
                )}
              </Card>
              <Card title="Right" style={{ ...styles.maxWidth, margin: "5px" }}>
                {rightImage && (
                  <Image src={rightImage} width="100%" preview={false} />
                )}
              </Card>
              <Card title="ROI" style={{ ...styles.maxWidth, margin: "5px" }}>
                {roiImage && (
                  <Image src={roiImage} width="100%" preview={false} />
                )}
              </Card>
            </Space>
          </Col>
        </Row>
      );
    } else {
      // Desktop layout
      return (
        <Row>
          <Col span={24}>
            <Row gutter={16} justify="center">
              <Col span={12}>
                <Card title="Left">
                  {leftImage && (
                    <Image src={leftImage} width="100%" preview={false} />
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Right">
                  {rightImage && (
                    <Image src={rightImage} width="100%" preview={false} />
                  )}
                </Card>
              </Col>
            </Row>
            <Row gutter={16} justify="center" style={{ marginTop: "15px" }}>
              <Col span={24}>
                <Card title="ROI">
                  {roiImage && (
                    <Image src={roiImage} width="100%" preview={false} />
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  return renderLayout();
};

export default CaptureViewer;
