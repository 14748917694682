import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAvYs3tE1pDu_27NlNuwSh0UrXx99XxkA0",
  authDomain: "lm-data-collect.firebaseapp.com",
  projectId: "lm-data-collect",
  storageBucket: "lm-data-collect.appspot.com",
  messagingSenderId: "668871582867",
  appId: "1:668871582867:web:709ecf98038b387d9bd966",
  measurementId: "G-B9L0L1E2PK",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
