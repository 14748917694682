import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../app/firebase";

export interface Capture {
  backspin?: number;
  ball_speed?: number;
  captureId?: string;
  horizontal_launch_angle?: number;
  recorded: Date;
  reported?: boolean;
  sidespin?: number;
  trigger_speed?: number;
  userFeedback?: string;
  vertical_launch_angle?: number;
  valid_launch?: boolean;
  left_vizualization?: string;
  right_visualization?: string;
  roi_visualization?: string;
}

// Define the initial state type
interface CapturesState {
  lastCapture: Capture | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  leftImage: string | null;
  rightImage: string | null;
  roiImage: string | null;
  unsubscribeFromCaptures?: () => void;
}

// Define the initial state
const initialState: CapturesState = {
  lastCapture: null,
  status: "idle",
  error: null,
  leftImage: null,
  rightImage: null,
  roiImage: null,
};

interface FetchOptions {
  userDocId: string;
  limit?: number;
}

export const cleanCapturePayload = (payload: any) => {
  const {
    _firestore,
    _userDataWriter,
    _key,
    _document,
    _converter,
    _firestoreImpl,
    ...cleanedPayload
  } = payload;
  return cleanedPayload;
};

export const listenLatestCapture = createAsyncThunk(
  "captures/listenLatestCapture",
  async (userDocId: string, { dispatch }) => {
    const now = new Date();
    let q = query(
      collection(doc(db, "users", userDocId), "captures"),
      where("recorded", ">", now),
      orderBy("recorded", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added" || change.type === "modified") {
          const capture = {
            ...change.doc.data(),
            captureId: change.doc.id,
            recorded: change.doc.data().recorded.toDate(),
          } as Capture;

          dispatch(lastCaptureAdded(capture));
        }
      });
    });
    dispatch(setUnsubscribeFromCaptures(unsubscribe));
    return unsubscribe;
  }
);

export const captureUpdated = createAction<Capture>("captures/captureUpdated");

export const lastCaptureAdded = createAction<Capture>(
  "captures/lastCaptureAdded"
);

export const fetchCaptures = createAsyncThunk(
  "captures/fetchCaptures",
  async (options: FetchOptions) => {
    let q = query(
      collection(doc(db, "users", options.userDocId), "captures"),
      orderBy("recorded", "desc")
    );
    if (options.limit) {
      q = query(q, limit(options.limit));
    }
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      recorded: doc.data().recorded.toDate(),
    })) as Capture[];
  }
);

interface UpdateCaptureWithFeedbackArgs {
  userDocId: string;
  lastCapture: Capture;
  userFeedback: string;
  additionalData?: Record<string, any>;
}

export const updateCaptureWithFeedback = createAsyncThunk(
  "captures/updateCaptureWithFeedback",
  async (args: UpdateCaptureWithFeedbackArgs, { dispatch }) => {
    const captureDoc = doc(
      db,
      "users",
      args.userDocId,
      "captures",
      args.lastCapture.captureId?.toString() || ""
    );

    try {
      await updateDoc(captureDoc, {
        reported: true,
        userFeedback: args.userFeedback,
        ...args.additionalData,
      });

      dispatch(
        captureUpdated({
          ...args.lastCapture,
          captureId: args.lastCapture.captureId,
          reported: true,
          userFeedback: args.userFeedback,
          ...args.additionalData,
        })
      );
    } catch (error) {
      console.error("Error updating capture:", error);
      throw error;
    }
  }
);

const capturesSlice = createSlice({
  name: "captures",
  initialState,
  reducers: {
    setUnsubscribeFromCaptures: (state, action: PayloadAction<() => void>) => {
      state.unsubscribeFromCaptures = action.payload;
    },
    setLeftImage: (state, action: PayloadAction<string>) => {
      state.leftImage = action.payload;
    },
    setRightImage: (state, action: PayloadAction<string>) => {
      state.rightImage = action.payload;
    },
    setRoiImage: (state, action: PayloadAction<string>) => {
      state.roiImage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCaptures.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchCaptures.fulfilled,
        (state, action: PayloadAction<Capture[]>) => {
          state.status = "succeeded";
          console.log("fetchCaptures.fulfilled:", action.payload);
          // state.captures = state.captures.concat(action.payload);
        }
      )
      .addCase(fetchCaptures.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(lastCaptureAdded, (state, action: PayloadAction<Capture>) => {
        state.lastCapture = action.payload;
      })
      .addCase(captureUpdated, (state, action: PayloadAction<Capture>) => {
        state.lastCapture = action.payload;
      });
  },
});

export const {
  setUnsubscribeFromCaptures,
  setLeftImage,
  setRightImage,
  setRoiImage,
} = capturesSlice.actions;
export default capturesSlice.reducer;
