import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import compressTransform from "redux-persist-transform-compress";
import { logoutMiddleware } from "../middleware/logoutMiddleware";
import userSlice from "../features/user/userSlice";
import capturesSlice from "../features/captures/capturesSlice";
import adminSlice from "../features/admin/adminSlice";

// Combine all reducers
const rootReducer = combineReducers({
  user: userSlice,
  captures: capturesSlice,
  admin: adminSlice,
});

// Define the RootState type
export type RootState = ReturnType<typeof rootReducer>;

// Define the persist config type
type PersistConfigType = PersistConfig<RootState> & {
  whitelist: (keyof RootState)[];
};

const persistConfig: PersistConfigType = {
  key: "root",
  storage,
  transforms: [
    compressTransform({
      whitelist: ["admin"], // Only apply transform to the 'admin' slice
    }),
  ],
  whitelist: ["user", "captures", "admin"], // Specify the slices to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(logoutMiddleware),
});

export const persistor = persistStore(store);

// Explicitly define the AppState and AppDispatch types
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

// Define Thunk and other types for async actions
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
