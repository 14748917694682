// src/utils/unsubscribeManager.ts
const unsubscribeFunctions: { [key: string]: (() => void) | undefined } = {};

export const addUnsubscribeFunction = (
  key: string,
  unsubscribe: () => void
) => {
  unsubscribeFunctions[key] = unsubscribe;
};

export const removeUnsubscribeFunction = (key: string) => {
  if (unsubscribeFunctions[key]) {
    unsubscribeFunctions[key]!(); // Use non-null assertion
    delete unsubscribeFunctions[key];
  }
};

export const clearUnsubscribeFunctions = () => {
  Object.keys(unsubscribeFunctions).forEach((key) => {
    if (typeof unsubscribeFunctions[key] === "function") {
      unsubscribeFunctions[key]!(); // Use non-null assertion
      delete unsubscribeFunctions[key];
    }
  });
};
