import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { useAdminLastCaptureSubscription } from "../../hooks/useAdminLastCaptureSubscription";
import CaptureViewer from "../CaptureViewer/CaptureViewer";
import {
  fetchUsers,
  setSubscribedUser,
  setUnsubscribeFromCaptures,
  listenLatestCaptureForAdmin,
  selectUserAndFetchDetails,
  User,
} from "../../features/admin/adminSlice";
import {
  Button,
  Space,
  Card,
  AutoComplete,
  Row,
  Col,
  Descriptions,
  Tabs,
  Table,
  Grid,
} from "antd";

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Admin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [speedUnit, setSpeedUnit] = useState<"mph" | "km/h" | "m/s">("mph");

  const { users, selectedUser, subscribedUser, unsubscribeFromCaptures } =
    useAppSelector((state) => state.admin);
  const subscribedUserLastCapture = useAppSelector(
    (state) => state.admin.subscribedUser?.lastCapture
  );

  useAdminLastCaptureSubscription(subscribedUser?.docId);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleUserClick = useCallback(
    async (user: User) => {
      await dispatch(selectUserAndFetchDetails(user));
    },
    [dispatch]
  );

  const handleSearch = useCallback(
    (value: string) => {
      const user = users.find((user) => user.email === value);
      user && handleUserClick(user);
    },
    [users, handleUserClick]
  );

  const handleSubscribe = useCallback(
    async (user: User) => {
      unsubscribeFromCaptures && unsubscribeFromCaptures();
      const unsubscribe = await dispatch(
        listenLatestCaptureForAdmin(user.docId)
      ).unwrap();
      dispatch(setSubscribedUser(user));
      dispatch(setUnsubscribeFromCaptures(unsubscribe));
    },
    [unsubscribeFromCaptures, dispatch]
  );

  const handleUnsubscribe = useCallback(() => {
    unsubscribeFromCaptures && unsubscribeFromCaptures();
    dispatch(setUnsubscribeFromCaptures(null));
    dispatch(setSubscribedUser(null));
  }, [unsubscribeFromCaptures, dispatch]);

  const handleSpeedUnitChange = () => {
    setSpeedUnit((prevUnit) =>
      prevUnit === "mph" ? "km/h" : prevUnit === "km/h" ? "m/s" : "mph"
    );
  };

  const screens = useBreakpoint();

  const renderMobileLayout = () => (
    <Row justify="center" style={{ padding: "5px" }}>
      <Col xs={24}>
        <Space
          direction="vertical"
          size={8}
          style={{ width: "100%", minWidth: "100%" }}
        >
          <Space>
            <AutoComplete
              style={{ width: 300 }}
              options={users
                .filter((user) => user.email)
                .map((user) => ({ value: user.email }))}
              placeholder="Search for a user"
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().includes(inputValue.toUpperCase())
              }
              onSelect={handleSearch}
            />
            <Button
              type={subscribedUser ? "default" : "primary"}
              danger={!!subscribedUser}
              onClick={
                subscribedUser
                  ? handleUnsubscribe
                  : () => handleSubscribe(selectedUser!)
              }
            >
              {subscribedUser ? "Unsubscribe" : "Subscribe"}
            </Button>
          </Space>
          {subscribedUserLastCapture ? (
            <>
              <Card
                title={
                  subscribedUserLastCapture?.reported
                    ? "Shot Data -> Reported"
                    : "Shot Data"
                }
                bodyStyle={{ padding: "0" }}
                style={
                  subscribedUserLastCapture?.reported
                    ? { backgroundColor: "red" }
                    : {}
                }
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong
                      style={{ textAlign: "right" }}
                      onClick={handleSpeedUnitChange}
                    >
                      Ball Speed ({speedUnit.toUpperCase()}):
                    </strong>
                    {speedUnit === "mph" && subscribedUserLastCapture.ball_speed
                      ? (subscribedUserLastCapture.ball_speed * 2.237).toFixed(
                          1
                        )
                      : speedUnit === "km/h" &&
                        subscribedUserLastCapture.ball_speed
                      ? (subscribedUserLastCapture.ball_speed * 3.6).toFixed(1)
                      : subscribedUserLastCapture.ball_speed?.toFixed(1)}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Backspin:</strong>
                    {subscribedUserLastCapture.backspin?.toFixed(1)}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Sidespin:</strong>
                    {subscribedUserLastCapture.sidespin?.toFixed(1)}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>
                      Horizontal Launch Angle:
                    </strong>
                    {subscribedUserLastCapture.horizontal_launch_angle?.toFixed(
                      1
                    )}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>
                      Vertical Launch Angle:
                    </strong>
                    {subscribedUserLastCapture.vertical_launch_angle?.toFixed(
                      1
                    )}
                  </li>
                </ul>
              </Card>
              <CaptureViewer lastCapture={subscribedUserLastCapture} />
            </>
          ) : (
            <div>Waiting for the next shot...</div>
          )}
        </Space>
      </Col>
    </Row>
  );

  const columns = [
    { title: "Capture ID", dataIndex: "captureId", key: "captureId" },
  ];

  const renderDesktopLayout = () => (
    <Row gutter={16} justify="center">
      <Col span={24}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Space>
              <AutoComplete
                style={{ width: 300 }}
                options={users
                  .filter((user) => user.email)
                  .map((user) => ({ value: user.email }))}
                placeholder="Search for a user"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().includes(inputValue.toUpperCase())
                }
                onSelect={handleSearch}
              />
              <Button
                type={subscribedUser ? "default" : "primary"}
                danger={!!subscribedUser}
                onClick={
                  subscribedUser
                    ? handleUnsubscribe
                    : () => handleSubscribe(selectedUser!)
                }
              >
                {subscribedUser ? "Unsubscribe" : "Subscribe"}
              </Button>
            </Space>
            <Button type="primary" onClick={() => dispatch(fetchUsers())}>
              Refresh Users
            </Button>
          </Row>
          <Row gutter={16}>
            {selectedUser && (
              <>
                <Col span={12}>
                  <Card
                    title={selectedUser.email}
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      padding: "0 20px",
                    }}
                  >
                    <Descriptions bordered layout="vertical">
                      <Descriptions.Item label="Number of Captures">
                        {selectedUser.totalCapturesCount}
                      </Descriptions.Item>
                      <Descriptions.Item label="Number of Reported Captures">
                        {selectedUser.reportedCapturesCount}
                      </Descriptions.Item>
                      <Descriptions.Item label="Last Capture Date">
                        {selectedUser.lastCapture?.recorded?.toString() ||
                          "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      padding: "0 20px",
                    }}
                  >
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Current User's Shot Data" key="1">
                        <Descriptions
                          bordered
                          column={1}
                          style={{ width: "100%" }}
                        >
                          <Descriptions.Item label="Backspin">
                            {subscribedUserLastCapture?.backspin?.toFixed(1) ??
                              "N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span onClick={handleSpeedUnitChange}>
                                Ball Speed ({speedUnit.toUpperCase()})
                              </span>
                            }
                          >
                            {subscribedUserLastCapture?.ball_speed
                              ? speedUnit === "mph"
                                ? (
                                    subscribedUserLastCapture.ball_speed * 2.237
                                  ).toFixed(1)
                                : speedUnit === "km/h"
                                ? (
                                    subscribedUserLastCapture.ball_speed * 3.6
                                  ).toFixed(1)
                                : subscribedUserLastCapture.ball_speed.toFixed(
                                    1
                                  )
                              : "N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Horizontal Launch Angle">
                            {subscribedUserLastCapture?.horizontal_launch_angle?.toFixed(
                              1
                            ) ?? "N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Sidespin">
                            {subscribedUserLastCapture?.sidespin?.toFixed(1) ??
                              "N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item label="Vertical Launch Angle">
                            {subscribedUserLastCapture?.vertical_launch_angle?.toFixed(
                              1
                            ) ?? "N/A"}
                          </Descriptions.Item>
                        </Descriptions>
                      </TabPane>
                      <TabPane tab="Reported Captures" key="2">
                        <Table
                          columns={columns}
                          dataSource={selectedUser.reportedCaptures}
                        />
                      </TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </>
            )}
          </Row>
          <Row>
            {subscribedUserLastCapture && (
              <CaptureViewer lastCapture={subscribedUserLastCapture} />
            )}
          </Row>
        </Space>
      </Col>
    </Row>
  );

  return screens.xs ? renderMobileLayout() : renderDesktopLayout();
};

export default Admin;
