import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AppState } from "../app/store";
import MainLayout from "../components/Layouts/MainLayout/MainLayout";
import { useAppSelector, useAppDispatch } from "../hooks/storeHooks";
import { authenticateWithToken } from "../features/user/userSlice"; // Import your token authentication action
import { auth } from "../app/firebase"; // Import Firebase Auth

const PrivateRoute: React.FC = () => {
  const user = useAppSelector((state: AppState) => state.user);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkFirebaseToken = async () => {
      try {
        // Check if there is a current user
        if (auth.currentUser) {
          // Get the Firebase ID token
          const token = await auth.currentUser.getIdToken();
          // Dispatch action to authenticate using the token
          dispatch(authenticateWithToken(token));
        }
      } catch (error) {
        console.error("Error verifying Firebase token:", error);
      }
    };

    checkFirebaseToken();
  }, [dispatch]);

  // Determine if the current path requires full height layout
  const fullHeightRoutes = ["/shotvisualizer"];
  const isFullHeight = fullHeightRoutes.includes(location.pathname);

  return user.currentUser ? (
    <MainLayout fullHeight={isFullHeight}>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
