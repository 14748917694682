import { getStorage, ref, getDownloadURL } from "firebase/storage";

const storage = getStorage();

export async function getFirebaseImageURL(path: string) {
  const storageRef = ref(storage, path);
  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error("Error fetching image URL:", error);
    return null;
  }
}
