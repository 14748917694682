import React from "react";
import { Layout, Menu, Drawer, Button, Grid } from "antd";
import {
  DeploymentUnitOutlined,
  UserOutlined,
  CloseOutlined,
  FundOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks/storeHooks";
import "./styles.css";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const menuItems = [
  {
    key: "1",
    icon: <UserOutlined />,
    label: (
      <Link to="/dashboard" className="menu-link">
        Dashboard
      </Link>
    ),
    path: "/dashboard",
  },
  {
    key: "2",
    icon: <FundOutlined />,
    label: (
      <Link to="/shotvisualizer" className="menu-link">
        Shot Visualizer
      </Link>
    ),
    path: "/shotvisualizer",
    adminOnly: true,
  },
  {
    key: "3",
    icon: <DeploymentUnitOutlined />,
    label: (
      <Link to="/admin" className="menu-link">
        Admin
      </Link>
    ),
    path: "/admin",
    adminOnly: true,
  },
];

type SiderMenuProps = {
  isMobile: boolean;
  onClose: () => void;
  visible: boolean;
};

const SiderMenu: React.FC<SiderMenuProps> = ({
  isMobile,
  onClose,
  visible,
}) => {
  const user = useAppSelector((state: any) => state.user.currentUser);
  const location = useLocation();
  const screens = useBreakpoint();

  const selectedKey =
    menuItems.find((item) => item.path === location.pathname)?.key || "1";

  const filteredMenuItems = menuItems
    .filter((item) => !item.adminOnly || (item.adminOnly && user?.admin))
    .map((item) => ({
      key: item.key,
      icon: item.icon,
      label: item.label,
    }));

  return isMobile ? (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ color: "#fff" }}>Menu</span>
          <Button
            type="text"
            onClick={onClose}
            icon={<CloseOutlined />}
            style={{ color: "#fff" }}
          />
        </div>
      }
      placement="left"
      closable={false}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ padding: 0, backgroundColor: "#041528" }}
      headerStyle={{
        backgroundColor: "#041528",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[selectedKey]}
        items={filteredMenuItems}
        onClick={onClose}
        style={{ backgroundColor: "#041528", color: "#fff" }}
      />
    </Drawer>
  ) : (
    <Sider
      style={{
        position: "fixed",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#041528",
      }}
      breakpoint="lg"
      collapsedWidth="0"
    >
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[selectedKey]}
        items={filteredMenuItems}
        style={{ backgroundColor: "#041528", color: "#fff" }}
      />
    </Sider>
  );
};

export default SiderMenu;
