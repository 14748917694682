import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "../components/Login/LoginPage";
import Dashboard from "../components/Dashboard/Dashboard";
import Admin from "../components/Admin/Admin";
import useAuthStateListener from "../hooks/useAuthStateListener";
import { useAppSelector, useAppDispatch } from "../hooks/storeHooks";
import { authenticateWithToken } from "../features/user/userSlice";

const AppRoutes = () => {
  useAuthStateListener();
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      dispatch(authenticateWithToken(token));
    }
  }, [dispatch, location.search]);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      {currentUser ? (
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="admin" element={<Admin />} />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
};

export default AppRoutes;
