import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  getDocs,
  onSnapshot,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../app/firebase";
import { Capture } from "../captures/capturesSlice";

export interface User {
  email: string;
  docId: string;
  reportedCaptures: Capture[];
  leftImage: string | null;
  rightImage: string | null;
  roiImage: string | null;
  totalCapturesCount: number;
  reportedCapturesCount: number;
  lastCapture?: Capture;
}

interface AdminState {
  users: User[];
  selectedUser: User | null;
  subscribedUser: User | null;
  unsubscribeFromCaptures: (() => void) | null;
}

const initialState: AdminState = {
  users: [],
  selectedUser: null,
  subscribedUser: null,
  unsubscribeFromCaptures: null,
};

export const fetchUsers = createAsyncThunk("admin/fetchUsers", async () => {
  const usersCollection = collection(db, "users");
  const q = query(usersCollection, orderBy("email"));
  const usersSnapshot = await getDocs(q);
  return usersSnapshot.docs.map((doc) => ({
    ...doc.data(),
    docId: doc.id,
    email: doc.data().email,
    captures: [],
    reportedCaptures: [],
    leftImage: null,
    rightImage: null,
    roiImage: null,
    totalCapturesCount: 0,
    reportedCapturesCount: 0,
  })) as User[];
});

const fetchUserCapturesCount = async (userDocId: string) => {
  const coll = collection(doc(db, "users", userDocId), "captures");
  const q = query(coll, where("valid_launch", "!=", false));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

const fetchReportedUserCapturesCount = async (userDocId: string) => {
  const coll = collection(doc(db, "users", userDocId), "captures");
  const q = query(coll, where("reported", "==", true));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const selectUserAndFetchDetails = createAsyncThunk(
  "admin/selectUserAndFetchDetails",
  async (user: User) => {
    const totalCapturesCount = await fetchUserCapturesCount(user.docId);
    const reportedCapturesCount = await fetchReportedUserCapturesCount(
      user.docId
    );
    return { ...user, totalCapturesCount, reportedCapturesCount };
  }
);

export const listenLatestCaptureForAdmin = createAsyncThunk(
  "admin/listenLatestCaptureForAdmin",
  async (userDocId: string, { dispatch }) => {
    const now = new Date();
    const q = query(
      collection(doc(db, "users", userDocId), "captures"),
      where("recorded", ">", now),
      orderBy("recorded", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added" || change.type === "modified") {
          const capture = {
            ...change.doc.data(),
            captureId: change.doc.id,
            recorded: change.doc.data().recorded.toDate(),
          } as Capture;

          dispatch(lastCaptureAdded(capture));
        }
      });
    });

    return unsubscribe;
  }
);

export const lastCaptureAdded = createAction<Capture>("admin/lastCaptureAdded");

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<User>) => {
      state.selectedUser = action.payload;
    },
    setSubscribedUser: (state, action: PayloadAction<User | null>) => {
      state.subscribedUser = action.payload;
    },
    setUnsubscribeFromCaptures: (
      state,
      action: PayloadAction<(() => void) | null>
    ) => {
      state.unsubscribeFromCaptures = action.payload;
    },
    setLeftImage: (state, action: PayloadAction<string>) => {
      if (state.selectedUser) {
        state.selectedUser.leftImage = action.payload;
      }
    },
    setRightImage: (state, action: PayloadAction<string>) => {
      if (state.selectedUser) {
        state.selectedUser.rightImage = action.payload;
      }
    },
    setRoiImage: (state, action: PayloadAction<string>) => {
      if (state.selectedUser) {
        state.selectedUser.roiImage = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(selectUserAndFetchDetails.fulfilled, (state, action) => {
      state.selectedUser = action.payload;
    });
    builder.addCase(lastCaptureAdded, (state, action) => {
      if (state.subscribedUser) {
        state.subscribedUser.lastCapture = action.payload;
      }
    });
  },
});

export const {
  setSelectedUser,
  setSubscribedUser,
  setUnsubscribeFromCaptures,
  setLeftImage,
  setRightImage,
  setRoiImage,
} = adminSlice.actions;

export default adminSlice.reducer;
