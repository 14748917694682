import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../app/firebase";
import { query, collection, where, getDocs } from "firebase/firestore";
import {
  setCurrentUser,
  setError,
  setLoading,
} from "../features/user/userSlice";
import { useAppDispatch } from "./storeHooks";

const useAuthStateListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const { uid, email, emailVerified, isAnonymous, providerData } = user;

        // Fetch the user document from Firestore
        const userQuery = query(
          collection(db, "users"),
          where("uid", "==", uid)
        );
        getDocs(userQuery).then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const docId = querySnapshot.docs[0].id;
            const admin = querySnapshot.docs[0].data().admin ? true : false;
            const formattedProviderData = providerData.map((provider: any) => ({
              providerId: provider.providerId,
              uid: provider.uid,
              displayName: provider.displayName || null,
              email: provider.email || null,
              phoneNumber: provider.phoneNumber || null,
              photoURL: provider.photoURL || null,
            }));

            dispatch(
              setCurrentUser({
                uid,
                email: email || "",
                emailVerified,
                isAnonymous,
                providerData: formattedProviderData,
                docId,
                admin,
              })
            );
          } else {
            dispatch(setError("User document not found"));
          }
        });
      } else {
        // User is signed out
        dispatch(setCurrentUser(null));
      }
      dispatch(setLoading(false));
    });

    return () => unsubscribe();
  }, [dispatch]);
};

export default useAuthStateListener;
