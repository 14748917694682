import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { AppState } from "../app/store";
import {
  listenLatestCaptureForAdmin,
  setLeftImage,
  setRightImage,
  setRoiImage,
} from "../features/admin/adminSlice";
import {
  addUnsubscribeFunction,
  removeUnsubscribeFunction,
} from "../utils/unsubscribeManager";

// Define the type for the return value of the dispatch function
type Unsubscribe = () => void;

export const useAdminLastCaptureSubscription = (userId: string | undefined) => {
  const dispatch = useAppDispatch();
  const unsubscribeRef = useRef<Unsubscribe | null>(null);

  const lastCapture = useAppSelector(
    (state: AppState) => state.admin.subscribedUser?.lastCapture
  );

  useEffect(() => {
    if (!userId) {
      return;
    }

    const subscribe = async () => {
      try {
        console.log(`Subscribing to captures for user: ${userId}`);
        const unsubscribe = (await dispatch(
          listenLatestCaptureForAdmin(userId)
        ).unwrap()) as Unsubscribe;
        unsubscribeRef.current = unsubscribe;
        addUnsubscribeFunction(userId, unsubscribe);
      } catch (error) {
        console.error("Error subscribing to latest captures: ", error);
      }
    };

    subscribe();

    return () => {
      if (unsubscribeRef.current) {
        console.log(`Unsubscribing from captures for user: ${userId}`);
        unsubscribeRef.current();
        unsubscribeRef.current = null;
        if (userId) {
          removeUnsubscribeFunction(userId);
        }
      }
    };
  }, [userId, dispatch]);

  useEffect(() => {
    const fetchImages = async () => {
      if (lastCapture?.left_vizualization) {
        dispatch(setLeftImage(lastCapture.left_vizualization));
      }

      if (lastCapture?.right_visualization) {
        dispatch(setRightImage(lastCapture.right_visualization));
      }

      if (lastCapture?.roi_visualization) {
        dispatch(setRoiImage(lastCapture.roi_visualization));
      }
    };

    if (lastCapture) {
      fetchImages();
    }
  }, [lastCapture, dispatch]);

  useEffect(() => {
    return () => {
      if (unsubscribeRef.current && userId) {
        console.log(`Manually unsubscribing from captures for user: ${userId}`);
        unsubscribeRef.current();
        unsubscribeRef.current = null;
        removeUnsubscribeFunction(userId);
      }
    };
  }, [userId]);

  return () => {
    if (unsubscribeRef.current && userId) {
      console.log(`Manually unsubscribing from captures for user: ${userId}`);
      unsubscribeRef.current();
      unsubscribeRef.current = null;
      removeUnsubscribeFunction(userId);
    }
  };
};
