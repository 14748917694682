import React, { useState } from "react";
import { Layout, Grid } from "antd";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";

const { Content } = Layout;
const { useBreakpoint } = Grid;

type LayoutProps = {
  children: React.ReactNode;
  fullHeight?: boolean;
};

const MainLayout: React.FC<LayoutProps> = ({
  children,
  fullHeight = false,
}) => {
  const screens = useBreakpoint();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar
        isMobile={!screens.lg}
        onClose={closeDrawer}
        visible={drawerVisible}
      />
      <Layout
        style={{
          marginLeft: screens.lg ? 200 : 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header toggleDrawer={toggleDrawer} />
        <Content
          style={{
            margin: screens.xs ? "5px" : "24px 16px 0",
            overflow: "hidden",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              padding: screens.xs ? 0 : 24,
              background: "#fff",
              textAlign: "center",
              boxSizing: "border-box",
              flexGrow: 1,
              height: fullHeight ? "100%" : "auto",
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
