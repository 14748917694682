import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/routes";

const App = () => {
  useEffect(() => {
    document.title = "Openlaunch Dashboard";
  }, []);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
