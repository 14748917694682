import { Middleware } from "@reduxjs/toolkit";
import { AppState } from "../app/store";
import { clearUnsubscribeFunctions } from "../utils/unsubscribeManager";

const logoutActionType = "user/logout";

export const logoutMiddleware: Middleware<{}, AppState> =
  (storeAPI) => (next) => (action: any) => {
    const result = next(action);
    if (action.type === logoutActionType) {
      clearUnsubscribeFunctions();
    }
    return result;
  };
