import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth } from "../../app/firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCustomToken,
} from "firebase/auth";
import { getDocs, query, where, collection } from "firebase/firestore";
import { db } from "../../app/firebase";
import { clearUnsubscribeFunctions } from "../../utils/unsubscribeManager";
import { AppDispatch } from "../../app/store";

interface ProviderData {
  providerId: string;
  uid: string;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
}

interface User {
  uid: string;
  docId: string;
  email: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  providerData: ProviderData[];
  admin: boolean;
}

interface UserState {
  currentUser: User | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  currentUser: null,
  isLoading: false,
  error: null,
};

const googleProvider = new GoogleAuthProvider();

export const login =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const {
        uid,
        email: userEmail,
        emailVerified,
        isAnonymous,
        providerData,
      } = userCredential.user;

      const userQuery = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const admin = querySnapshot.docs[0].data().admin || false;
        const formattedProviderData = providerData.map((provider: any) => ({
          providerId: provider.providerId,
          uid: provider.uid,
          displayName: provider.displayName || null,
          email: provider.email || null,
          phoneNumber: provider.phoneNumber || null,
          photoURL: provider.photoURL || null,
        }));

        dispatch(
          setCurrentUser({
            uid,
            email: userEmail || "",
            emailVerified,
            isAnonymous,
            providerData: formattedProviderData,
            docId,
            admin,
          })
        );
      } else {
        throw new Error("User document not found");
      }

      dispatch(setLoading(false));
      dispatch(clearError());
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Unknown error occurred"));
      }
      dispatch(setLoading(false));
    }
  };

export const signInWithGoogle = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    if (user) {
      const { uid, email, emailVerified, isAnonymous, providerData } = user;

      const userQuery = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const admin = querySnapshot.docs[0].data().admin || false;
        const formattedProviderData = providerData.map((provider: any) => ({
          providerId: provider.providerId,
          uid: provider.uid,
          displayName: provider.displayName || null,
          email: provider.email || null,
          phoneNumber: provider.phoneNumber || null,
          photoURL: provider.photoURL || null,
        }));

        dispatch(
          setCurrentUser({
            uid,
            email: email || "",
            emailVerified,
            isAnonymous,
            providerData: formattedProviderData,
            docId,
            admin,
          })
        );
      } else {
        console.log("User document not found");
      }
    }
    dispatch(setLoading(false));
  } catch (error: unknown) {
    if (error instanceof Error) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError("Unknown error occurred"));
    }
    dispatch(setLoading(false));
  }
};

export const authenticateWithToken = createAsyncThunk(
  "user/authenticateWithToken",
  async (token: string, { rejectWithValue }) => {
    try {
      const userCredential = await signInWithCustomToken(auth, token);
      const { uid, email, emailVerified, isAnonymous, providerData } =
        userCredential.user;

      // Fetch user details from Firestore
      const userQuery = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const admin = querySnapshot.docs[0].data().admin || false;
        return {
          uid,
          email: email || "",
          emailVerified,
          isAnonymous,
          providerData,
          docId,
          admin,
        };
      } else {
        throw new Error("User document not found in Firestore");
      }
    } catch (error: unknown) {
      // Handle token or Firebase auth errors
      return rejectWithValue(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
    }
  }
);

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    clearUnsubscribeFunctions(); // Unsubscribe all active subscriptions
    await signOut(auth);
    dispatch(setCurrentUser(null));
    dispatch(setLoading(false));
  } catch (error: unknown) {
    if (error instanceof Error) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError("Unknown error occurred"));
    }
    dispatch(setLoading(false));
  }
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateWithToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(authenticateWithToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = {
          ...action.payload,
          email: action.payload.email || "", // Ensure email is always a string
        };
      })
      .addCase(authenticateWithToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setCurrentUser, setLoading, setError, clearError } =
  userSlice.actions;

export default userSlice.reducer;
