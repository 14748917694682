import React from "react";
import { Layout, Space, Avatar, Dropdown, Menu, Button, Grid } from "antd";
import { UserOutlined, MenuOutlined } from "@ant-design/icons";
import { logout } from "../../features/user/userSlice";
import { useAppDispatch } from "../../hooks/storeHooks";

const { Header } = Layout;
const { useBreakpoint } = Grid;

const HeaderBar = ({ toggleDrawer }: { toggleDrawer: any }) => {
  const dispatch = useAppDispatch();
  const screens = useBreakpoint();

  const items = [
    {
      key: "1",
      label: "Logout",
      onClick: () => dispatch(logout()),
    },
  ];

  return (
    <Header
      style={{
        background: "#041528",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 1,
        padding: "0 16px",
      }}
    >
      {!screens.lg && (
        <Button
          type="primary"
          onClick={toggleDrawer}
          style={{
            backgroundColor: "#6c757d",
            borderColor: "#6c757d",
          }}
          icon={<MenuOutlined style={{ color: "#fff" }} />}
        />
      )}
      <Space size="large" style={{ marginLeft: "auto" }}>
        <Dropdown overlay={<Menu items={items} />}>
          <Avatar
            icon={<UserOutlined style={{ color: "#fff" }} />}
            style={{ backgroundColor: "#6c757d" }}
          />
        </Dropdown>
      </Space>
    </Header>
  );
};

export default HeaderBar;
